<script setup lang="ts">
import request from "@/services/request";

const config = useRuntimeConfig();
const onboardingStore = useOnboardingStore();

const email = ref("");
const emailError = ref(false);
const showSuccessMessage = ref(false);
const currentApiSubscribe = computed(() => {
  return `${config.public.baseApiEnpoint}/api/marketing/blog-subscriptions`;
});

const validateEmail = () => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
  return re.test(String(email.value).toLowerCase());
};

const submitForm = () => {
  showSuccessMessage.value = false;

  if (!validateEmail()) {
    emailError.value = true;
    showSuccessMessage.value = false;
    return;
  }
  emailError.value = false;

  const formData = {
    email: email.value,
    pageUrl: window.location.href,
    location: "footer",
    abandonedCartId: onboardingStore.abandonedCartId,
  };

  request
    .post(currentApiSubscribe.value, formData)
    .then((response) => {
      showSuccessMessage.value = true;
      email.value = "";
    })
    .catch((error) => {
      emailError.value = true;
      console.error("There was a problem with your submission:", error);
      showSuccessMessage.value = false;
    });
};
</script>
<template>
  <footer class="tw-text-nl-yellow-100 tw-font-medium tw-text-sm">
    <div
      class="tw-relative tw-bg-nl-green-400 tw-mt-[50px] md:tw-mt-[60px] tw-pt-px tw-pb-[80px] md:tw-pb-[18px]"
    >
      <div
        class="tw-hidden md:tw-block tw-absolute tw-bottom-full md:-tw-mb-0.5 tw-inset-x-0 tw-h-[62px] footerDecorDesktop"
      ></div>
      <div
        class="tw-block md:tw-hidden tw-absolute tw-bottom-full md:-tw-mb-0.5 tw-inset-x-0 tw-h-[25px] min-[540px]:tw-h-[40px] tw-ml-[-2.5%] md:ml-0 footerDecorMobile"
      ></div>
      <div class="tw-container tw-mt-2 md:-tw-mt-7 tw-relative">
        <div
          class="tw-max-w-[716px] lg:tw-max-w-[778px] md:tw-px-[34px] tw-mx-auto tw-overflow-hidden"
        >
          <div class="tw-mb-3 md:tw-mb-[21px] lg:tw-ml-8">
            <div class="tw-max-w-[197px] tw-mx-auto md:tw-ml-0 tw-mb-2">
              <img
                src="https://content.nurturelife.com/wp-content/uploads/2021/07/5Sui9-pw.png"
                alt="Nurture Life"
              />
            </div>
            <div
              class="tw-text-white tw-text-base tw-pl-0.5 tw-text-center md:tw-text-left"
            >
              Growing up. Eating well.
            </div>
          </div>
          <div
            class="tw-flex tw-flex-col tw-flex-col-reverse md:tw-flex-row tw-justify-between"
          >
            <div class="md:tw-w-1/2">
              <div
                class="md:tw-pl-1 md:tw-max-w-[300px] lg:tw-ml-8 tw-w-full tw-pr-3 md:tw-pr-0"
              >
                <ul class="tw-columns-2">
                  <li class="tw-mb-[11px]">
                    <nuxt-link to="/" class="hover:tw-underline">Home</nuxt-link>
                  </li>
                  <li class="tw-mb-[11px]">
                    <nuxt-link href="/faq" class="hover:tw-underline">FAQ</nuxt-link>
                  </li>
                  <li class="tw-mb-[11px]">
                    <nuxt-link to="/how-it-works/" class="hover:tw-underline">How It Works</nuxt-link>
                  </li>
                  <li class="tw-mb-[11px]">
                    <nuxt-link to="/food-nutrition/" class="hover:tw-underline">Food Nutrition</nuxt-link>
                  </li>
                  <li class="tw-mb-[11px]">
                    <nuxt-link to="/packaging/" class="hover:tw-underline">Packaging</nuxt-link>
                  </li>
<!--                  <li class="tw-mb-[11px]">-->
<!--                    <nuxt-link to="#" class="hover:tw-underline"-->
<!--                      >Our Story</nuxt-link-->
<!--                    >-->
<!--                  </li>-->
                  <li class="tw-mb-[11px]">
                    <nuxt-link to="/what-we-stand-for/" class="hover:tw-underline">What We Stand For</nuxt-link>
                  </li>
                  <li class="tw-mb-[11px]">
                    <nuxt-link to="/blog/" class="hover:tw-underline">Blog</nuxt-link>
                  </li>
                  <li class="tw-mb-[11px]">
                    <a href="https://nurturelife.zohorecruit.com/jobs/Careers" target="_blank" class="hover:tw-underline">Careers</a>
                  </li>
                  <li class="tw-mb-[11px]">
                    <nuxt-link to="/contact-us/" class="hover:tw-underline">Contact</nuxt-link>
                  </li>
                </ul>
                <ul
                  class="tw-text-[11px] tw-flex tw-flex-wrap tw-pt-1.5 md:tw-pt-4 tw-gap-x-6 min-[375px]:tw-gap-x-14 md:tw-gap-x-6"
                >
                  <li class="tw-pt-2">{{  new Date().getFullYear() }} Nurture Life, Inc.</li>
                  <li class="tw-pt-2">
                    <nuxt-link to="/terms/" class="hover:tw-underline">Terms</nuxt-link>
                    |
                    <nuxt-link to="/privacy-policy/" class="hover:tw-underline">Privacy Policy</nuxt-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="md:tw-w-1/2">
              <div
                class="md:tw-max-w-[306px] tw-w-full tw-ml-auto tw-text-center md:tw-text-left tw-flex tw-flex-col-reverse md:tw-block tw-mb-[22px] md:tw-mb-0"
              >
                <div>
                  <div
                    class="tw-text-white tw-text-base tw-font-bold -tw-mt-2 tw-mb-2.5"
                  >
                    Get offers, new meal updates &amp; more!
                  </div>
                  <form
                    @submit.prevent="submitForm"
                    id="footer-form-newsletter"
                    novalidate
                  >
                    <div class="tw-flex footer-form" v-if="!showSuccessMessage">
                      <label for="email"
                        ><span class="sr-only">Your email address</span></label
                      >
                      <input
                        id="footer-email"
                        v-model="email"
                        type="email"
                        name="email"
                        :class="{ 'border-red-100': emailError }"
                        autocomplete="email"
                        placeholder="Your email address"
                        class="tw-bg-white tw-text-sm tw-rounded-l-[7px] tw-rounded-r-none tw-text-nl-green-100 placeholder:tw-text-nl-green-100 tw-w-full tw-flex-1 tw-h-10 tw-py-2 tw-px-4 tw-appearance-none"
                      />
                      <button
                        class="tw-bg-nl-blue-200 tw-text-nl-green-100 tw-flex tw-justify-center tw-items-center tw-w-11 tw-h-10 tw-rounded-r-[7px] tw-text-xl tw-pr-0.5 tw-pt-0.5 tw-border-l-[2px] tw-border-nl-green-100 hover:tw-bg-nl-blue-600 focus:tw-bg-nl-blue-600 tw-transition-colors"
                      >
                        <span class="icon-main-send"
                          ><span class="sr-only">submit</span></span
                        >
                      </button>
                    </div>
                    <div
                      v-if="showSuccessMessage"
                      aria-live="polite"
                      class="tw-text-center tw-w-full tw-border-0 tw-border-b tw-font-bookmania sm:tw-text-f18 tw-text-f16 tw-font-semibold tw-pt-7 tw-tracking-a08 tw-min-w-0"
                    >
                      You're all set! Check your inbox for an email from us now!
                    </div>
                    <div
                      v-if="emailError"
                      role="alert"
                      id="footer-email-error"
                      class="tw-max-w-266 tw-text-red-100 tw-text-f14 tw-leading-a35 tw-font-bold tw-pt-4"
                    >
                      <i class="icon-main-error tw-mr-1 tw-relative tw-top-0.5"></i>
                      Please enter a valid email address.
                    </div>
                  </form>
                </div>
                <ul
                  class="tw-flex tw-flex-wrap tw-justify-center md:tw-justify-start tw-text-2xl tw-pl-0.5 tw-pt-1 tw-pb-5 md:tw-pb-0 md:tw-pt-[68px] tw-gap-2 tw-text-white"
                >
                  <li>
                    <a
                      href="https://www.instagram.com/nurturelife"
                      class="tw-block tw-leading-none tw-text-white hover:tw-text-nl-blue-100 focus:tw-text-nl-blue-100 tw-transition-colors"
                      ><span class="icon-main-instagram"
                        ><span class="sr-only">instagram</span></span
                      ></a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/nurturelife/"
                      class="tw-block tw-leading-none tw-text-white hover:tw-text-nl-blue-100 focus:tw-text-nl-blue-100 tw-transition-colors"
                      ><span class="icon-main-facebook"
                        ><span class="sr-only">facebook</span></span
                      ></a
                    >
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/nurturelife"
                      class="tw-block tw-leading-none tw-text-white hover:tw-text-nl-blue-100 focus:tw-text-nl-blue-100 tw-transition-colors"
                      ><span class="icon-main-twitter"
                        ><span class="sr-only">twitter</span></span
                      ></a
                    >
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/@nurturelife"
                      class="tw-block tw-leading-none tw-text-white hover:tw-text-nl-blue-100 focus:tw-text-nl-blue-100 tw-transition-colors"
                      ><span class="icon-main-tiktok"
                        ><span class="sr-only">tiktok</span></span
                      ></a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
@import url("~/assets/css/app.css");

.footerDecorDesktop {
  background: url("/assets/images/home/footer/footer-arc-desktop.svg") repeat-x
    50% 0 / auto 100%;
}

.footerDecorMobile {
  background: url("/assets/images/home/footer/footer-arc-mobile.svg") 0 0 /
    cover no-repeat;
}
</style>
